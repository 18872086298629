import React from 'react';
import App from 'next/app';
import { Provider } from 'react-redux';
import { withIntlApp } from '@moxy/next-intl';
import Head from 'next/head';
import Script from 'next/script';
import { reduxStore } from '../src/redux/configureStore';
import '../src/assets/styles/main.scss';
import { config } from '../src/config/config';
import { IsPreviewContext } from '../src/utils/IsPreviewContext';
import { Base } from '../src/layouts/BaseComponent';

class MyApp extends App {
  render(): JSX.Element {
    /* eslint-disable react/no-danger */
    return (
      <Provider store={reduxStore}>
        <Head>
          <meta
            name="viewport"
            key="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                `window.gTagID = "${config.gTagID}"\n` +
                'window.dataLayer = window.dataLayer || [];\n' +
                'function gtag(){dataLayer.push(arguments);}\n' +
                "gtag('js', new Date());" +
                "gtag('consent', 'default', {\n" +
                "    analytics_storage: 'denied'\n" +
                '});'
            }}
          />
          {config.fbPixelID && (
            <script
              dangerouslySetInnerHTML={{
                __html:
                  '!function(f,b,e,v,n,t,s)\n' +
                  '{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
                  'n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
                  "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
                  'n.queue=[];\n' +
                  "}(window, document,'script');\n" +
                  `fbq('init', '${config.fbPixelID}');\n` +
                  "fbq('track', 'PageView');" +
                  "fbq('consent', 'revoke');"
              }}
            />
          )}
          <script
            dangerouslySetInnerHTML={{
              __html: `window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`
            }}
          />
          {config.clarityID && (
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(c,l,a){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    })(window, document, "clarity");`
              }}
            />
          )}
          {config.segmentID && (
            <script
              dangerouslySetInnerHTML={{
                __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)};analytics.SNIPPET_VERSION="4.1.0";`
              }}
            />
          )}
        </Head>
        {config.clarityID && (
          <Script src={`https://www.clarity.ms/tag/${config.clarityID}`} strategy="afterInteractive" />
        )}
        {config.gTagID && (
          <Script src={`https://www.googletagmanager.com/gtag/js?id=${config.gTagID}`} strategy="afterInteractive" />
        )}
        {config.fbPixelID && (
          <Script src="https://connect.facebook.net/en_US/fbevents.js" strategy="afterInteractive" />
        )}
        {config.segmentID && (
          <Script
            src={`https://cdn.segment.com/analytics.js/v1/${config.segmentID}/analytics.min.js`}
            strategy="afterInteractive"
          />
        )}
        {config.plausible && (
          <Script
            src={`${config.plausible.url}js/script.manual.js`}
            strategy="afterInteractive"
            data-domain={config.plausible.siteId}
          />
        )}
        <IsPreviewContext.Provider value={false}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Base {...this.props} />
        </IsPreviewContext.Provider>
      </Provider>
    );
  }
}

const loadLocale = async (locale = 'en') => {
  const module = await import(/* webpackChunkName: "intl-messages" */ `../src/lang/${locale}.json`);
  return module.default;
};

// eslint-disable-next-line import/no-default-export
export default withIntlApp(loadLocale)(MyApp);
