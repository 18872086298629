import { getCookie, setCookie } from 'typescript-cookie';
import { v4 as uuidv4 } from 'uuid';
import { config } from '../config/config';

const cookieKey = 'anonymousIdentifierV1';
export const anonymousIdentifier = () => {
  const existingID = getCookie(cookieKey);
  if (existingID) {
    return existingID;
  }
  const newID = uuidv4().toString();
  setCookie(cookieKey, newID, { domain: config.cookieDomain });
  return newID;
};
