import { get, set } from 'local-storage';
import { useEffect } from 'react';
import { logger } from '../utils/logger';
import { anonymousIdentifier } from './anonymousIdentifier';
import { getParameterByName } from './utils';

interface MarketingValue {
  timestamp: number;
  value: string;
}

export const storeMarketingValue = (key: string, value: string): void => {
  const fullKey = `marketing.${key}`;

  if (value) {
    const now = Math.floor(Date.now() / 1000);
    const data = {
      value,
      timestamp: now
    };
    set<MarketingValue>(fullKey, data);
  }
};
export const marketingParamsToStore = [
  'ref',
  'source',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term'
];
export const useStoreMarketingValues = (): void => {
  useEffect(() => {
    marketingParamsToStore.forEach((key) => {
      const value = getParameterByName(key, window.location.href);
      if (value) {
        storeMarketingValue(key, value);
      }
    });
  }, []);
};
export const marketingValue: (key: string) => string | undefined = (key): string | undefined => {
  const fullKey = `marketing.${key}`;
  const storedData = get<MarketingValue>(fullKey);
  if (!storedData) {
    return undefined;
  }

  try {
    const { timestamp, value } = storedData;
    const now = Math.floor(Date.now() / 1000);
    const difference = now - timestamp;

    if (difference < 28 * 24 * 3600) {
      return value;
    }
  } catch (e) {
    return '';
  }
  return '';
};

export const getMarketingValues = (): Record<string, string | undefined> => {
  return Object.fromEntries(marketingParamsToStore.map((key) => [key, marketingValue(key)]));
};

export const trackEvent = (event: string, data: Record<any, any>): void => {
  logger('trackEvent', event, data);
  if (window.gtag) {
    window.gtag('event', event, data);
  }
  if (window.analytics) {
    window.analytics.track(`WEB ${event}`, { ...data, smlSource: 'web' });
  }
  window.plausible?.(event, { props: data });
  if (window.fbq) {
    window.fbq('track', event, data);
  }
};
export const identifyAnonymous = (): void => {
  logger('identifyAnonymous', anonymousIdentifier());
  if (window.analytics) {
    window.analytics.identify({
      anonymousIdentifier: anonymousIdentifier()
    });
  }

  window.clarity?.('identify', anonymousIdentifier());

  if (window.gtag) {
    window.gtag('config', window.gTagID, {
      user_id: anonymousIdentifier()
    });
  }
};
export const onCookieConsent = () => {
  window.clarity?.('consent');

  window.gtag?.('consent', 'update', {
    analytics_storage: 'granted'
  });
  window.fbq?.('consent', 'grant');
};

export const trackPageView = (): void => {
  const url = new URL(window.location.href);
  const marketingValues = getMarketingValues();
  Object.keys(marketingValues).forEach((key) => {
    const value = marketingValues[key];
    if (value) {
      url.searchParams.set(key, value);
    }
  });

  const marketingUrl = url.toString();
  console.log(`Page changed: ${marketingUrl}`);

  // https://plausible.io/docs/custom-query-params
  window.plausible?.('pageview', { u: marketingUrl });
  if (window.gtag) {
    window.gtag('config', window.gTagID, { page_path: marketingUrl });
  }
  if (window.analytics) {
    window.analytics.page({ smlSource: 'web' }).catch(console.error);
  }
};

export const trackBlogCTA = (data: Record<string, unknown>): void => {
  trackEvent('Blog CTA Clicked', data);
};
export const trackBlogCTAFYM = (data: Record<string, unknown>): void => {
  trackEvent('Blog CTA FYM Clicked', data);
};

export const trackSignUpClicked = (data: Record<string, unknown>): void => {
  trackEvent('SignUp Clicked', data);
};
