import { combineReducers } from '@reduxjs/toolkit';
import { createApiThunk } from '../../createApiThunk';
import { createApiSlice } from '../../createApiSlice';
import { fymApi } from '../../../components/fymApi/FymAPI';

// Switch Realm partition
export const adminDevicesSwitchRealmPartition = createApiThunk(
  'adminDevicesSwitchRealmPartition',
  fymApi.adminDevicesSwitchRealmPartition.bind(fymApi)
);

const adminDevicesSwitchRealmPartitionSlice = createApiSlice(
  'adminDevicesSwitchRealmPartition',
  adminDevicesSwitchRealmPartition
);

export const adminDevicesReducer = combineReducers({
  devicesSwitchRealmPartition: adminDevicesSwitchRealmPartitionSlice.reducer
});
