import { Config, ConfigEnvironment } from './ConfigType';

export const config: Config = {
  env: ConfigEnvironment.PRODUCTION,
  release: 'e352c861dc25a69ff9103443bfef05931e142c1a',
  appProtocol: 'fym',
  isDebug: false,
  googlePlayStoreDisabled: false,
  sentryDSN: 'https://7bab568d62864af39ab6df3d266ea9a7@sentry.io/2709454',
  cookieDomain: 'music24.com',
  domain: 'https://music24.com',
  smartlinksAppDomain: 'https://app.music24.com',
  fymAPI: '/api_api/',
  fymServerAPI: 'https://api.freeyourmusic.com/',
  gTagID: 'G-NV694V3FK1',
  clarityID: 'c9ldc5vkel',
  fbPixelID: '677372367056988',
  segmentID: '8iH5tifl5CbtdfQH4Fx6UoU5G8nbCQmI',
  imgixHost: 'smartlinksapp.imgix.net',
  appleAffiliateToken: '1001l38Lj',
  plausible: {
    siteId: 'music24.com',
    url: 'https://plausible.freeyourmusic.com/'
  },
  commentBoxId: '5725336402657280-proj',
  mapkitToken:
    'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik5DOVlDNUM4OVIifQ.eyJpc3MiOiJGVTUzTkVaSlpWIiwiaWF0IjoxNTgzOTM5MjQ1LCJleHAiOjE2MTU0MjA4MDAsIm9yaWdpbiI6Imh0dHBzOi8vZnJlZXlvdXJtdXNpYy5jb20ifQ.fZ5sliPQo3WoY-kL-pulobaWm0wxrjpuwDZcGaYwDn9T6FHmSFZuZaAYhYwe0c27MvqhrjIK0lOZGK2ZCAVbhQ',
  strapi: {
    url: 'https://cms.music24.com',
    token:
      '51220cd27d240e80ea849a583d98783f4bd03bf57c36888adc33c0aff157f563e475ba11361d22b3bbb4c4f3373e705c34c8453326f92dbc3ed7a642e2a24be3934065fb5a4c8530489152f9ce3b21cce0455c8efeb6fca7d39e132c8a601ad2c299e8e09edc0f73f30244529c63c01dc2d1c31ef8ba8609f25a62e4492b4410'
  },
  blackFriday: {
    coupon: 'BF2021',
    discountValue: '30',
    startsAt: new Date(2021, 10, 20, 0, 0, 0, 0),
    endsAt: new Date(2021, 10, 30, 0, 0, 0, 0) // month is 0-11 and days are 1-31, fuck JS
  }
};
