import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { RequestParams } from '../components/fymApi/types/types';

export const createApiThunk = <ResultType, ApiCallParams = void>(
  typePrefix: string,
  apiCall: (params: RequestParams<ApiCallParams>) => Promise<ResultType>
  // eslint-disable-next-line @typescript-eslint/ban-types
): AsyncThunk<ResultType, ApiCallParams, {}> => {
  return createAsyncThunk<ResultType, ApiCallParams>(typePrefix, async (params, thunkAPI) => {
    const { signal } = thunkAPI;
    return apiCall({ signal, ...params });
  });
};
