import { combineReducers } from '@reduxjs/toolkit';
import { createApiThunk } from '../../createApiThunk';
import { createApiSlice } from '../../createApiSlice';
import { fymApi } from '../../../components/fymApi/FymAPI';
import { adminLicensesRemovePersonalData } from '../licenses/slice';

export const adminStatsList = createApiThunk('adminStatsList', fymApi.adminStatsList.bind(fymApi));
const adminStatsListSlice = createApiSlice('statsList', adminStatsList);

export const adminStatsDetails = createApiThunk('adminStatsDetails', fymApi.adminStatsDetails.bind(fymApi));
const adminStatsDetailsSlice = createApiSlice('adminStatsDetails', adminStatsDetails);

export const adminStatsRemoveStatData = createApiThunk(
  'adminStatsRemoveStatData',
  fymApi.adminStatsRemoveStatData.bind(fymApi)
);
const adminStatsRemoveStatDataSlice = createApiSlice(
  'adminLicensesRemovePersonalData',
  adminLicensesRemovePersonalData
);

export const adminStatsReducer = combineReducers({
  statsList: adminStatsListSlice.reducer,
  statsDetails: adminStatsDetailsSlice.reducer,
  statsRemoveStatData: adminStatsRemoveStatDataSlice.reducer
});
