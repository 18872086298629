import { combineReducers, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { get, set } from 'local-storage';
import jwtDecode from 'jwt-decode';
import { createApiThunk } from '../createApiThunk';
import { createApiSlice } from '../createApiSlice';
import { fymApi } from '../../components/fymApi/FymAPI';
import { tokenLocalStorageKey } from './consts';

export const authenticateLogin = createApiThunk('authenticateLogin', fymApi.login.bind(fymApi));
const authenticateLoginSlice = createApiSlice('authenticateLogin', authenticateLogin);

interface UserSliceState {
  loginField: string;
  passwordField: string;
  token?: string;
  userData?: {
    id: number;
    email: string;
    isAdmin: boolean;
  };
}

function parseJWTToUserData(token: string | undefined): UserSliceState['userData'] {
  if (!token) {
    return undefined;
  }
  return jwtDecode<UserSliceState['userData']>(token);
}

const initialToken = get<string | undefined>(tokenLocalStorageKey) ?? undefined;
const initialUserData = parseJWTToUserData(initialToken) ?? undefined;

const usersSlice = createSlice<UserSliceState, SliceCaseReducers<UserSliceState>>({
  name: 'users',
  initialState: {
    loginField: '',
    passwordField: '',
    token: initialToken,
    userData: initialUserData
  },
  reducers: {
    updateLoginField: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.loginField = action.payload;
    },
    updatePasswordField: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.passwordField = action.payload;
    },
    logout: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.token = undefined;
      // eslint-disable-next-line no-param-reassign
      state.userData = undefined;
      set<string | undefined>(tokenLocalStorageKey, undefined);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authenticateLogin.fulfilled, (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.token = payload.token;
      // eslint-disable-next-line no-param-reassign
      state.userData = parseJWTToUserData(payload.token);

      set<string>(tokenLocalStorageKey, payload.token);
    });
  }
});

export const usersReducer = combineReducers({
  slice: usersSlice.reducer,
  authenticateLogin: authenticateLoginSlice.reducer
});
export const { updateLoginField, updatePasswordField, logout } = usersSlice.actions;
