export enum PaddleProductId {
  // Old Products
  Bundle = 510699,
  Android = 510653,
  iOS = 510652,
  Desktop = 504694,

  // New Products
  App = 584857,
  Lifetime = 633565,

  // Subscriptions
  QuarterlyPlan = 585869,
  YearlyPlan = 586830,

  // SmartLinks
  SmartLinksInfluencer = 634310,
  SmartLinksArtist = 634311,
  SmartLinksRookie = 776739
}

export const PaddleDefaultPrices: Record<PaddleProductId, { price: number; currency: string }> = {
  // Old
  [PaddleProductId.Bundle]: {
    price: 12.99,
    currency: 'USD'
  },
  [PaddleProductId.Android]: {
    price: 9.89,
    currency: 'USD'
  },
  [PaddleProductId.iOS]: {
    price: 9.89,
    currency: 'USD'
  },
  [PaddleProductId.Desktop]: {
    price: 9.89,
    currency: 'USD'
  },
  // New
  [PaddleProductId.App]: {
    price: 9.89,
    currency: 'USD'
  },
  [PaddleProductId.Lifetime]: {
    price: 99,
    currency: 'USD'
  },
  [PaddleProductId.QuarterlyPlan]: {
    price: 9.89,
    currency: 'USD'
  },
  [PaddleProductId.YearlyPlan]: {
    price: 19.89,
    currency: 'USD'
  },
  [PaddleProductId.SmartLinksInfluencer]: {
    price: 9,
    currency: 'USD'
  },
  [PaddleProductId.SmartLinksArtist]: {
    price: 19,
    currency: 'USD'
  },
  [PaddleProductId.SmartLinksRookie]: {
    price: 4,
    currency: 'USD'
  }
};

export const allProductIds = [
  PaddleProductId.Bundle,
  PaddleProductId.Android,
  PaddleProductId.iOS,
  PaddleProductId.Desktop,
  PaddleProductId.App,
  PaddleProductId.Lifetime,
  PaddleProductId.QuarterlyPlan,
  PaddleProductId.YearlyPlan,
  PaddleProductId.SmartLinksInfluencer,
  PaddleProductId.SmartLinksArtist,
  PaddleProductId.SmartLinksRookie
];
