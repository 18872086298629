export enum ConfigEnvironment {
  LOCAL,
  STAGING,
  BETA,
  PRODUCTION
}

export interface Config {
  env: ConfigEnvironment;
  release: string;
  appProtocol: string;
  gTagID: string;
  clarityID: string;
  fbPixelID: string;
  segmentID: string;
  isDebug: boolean;
  googlePlayStoreDisabled: boolean;
  sentryDSN?: string;
  domain: string;
  cookieDomain: string;
  smartlinksAppDomain: string;
  fymAPI: string;
  fymServerAPI: string;
  commentBoxId: string;
  strapi: { token: string; url: string };
  mapkitToken: string;
  imgixHost: string;
  appleAffiliateToken: string;
  plausible?: {
    siteId: string;
    url: string;
  };
  blackFriday: {
    coupon: string;
    discountValue: string;
    startsAt: Date;
    endsAt: Date;
  };
}
