import qs from 'qs';
import { config } from '../../config/config';
import { FymAPILoginResponse } from './types/FymAPILoginResponse';
import { RequestParams } from './types/types';
import { FymAPIAdminLicensesListResponse } from './types/FymAPIAdminLicensesListResponse';
import { FymAPIAdminLicensesDetailsResponse } from './types/FymAPIAdminLicensesDetailsResponse';
import { FymAPIAdminLicensesSendLicenseEmailResponse } from './types/FymAPIAdminLicensesSendLicenseEmailResponse';
import { FymAPIAdminLicensesRemovePersonalDataResponse } from './types/FymAPIAdminLicensesRemovePersonalDataResponse';
import { FymAPIAdminLicensesChangeEmailResponse } from './types/FymAPIAdminLicensesChangeEmailResponse';
import {
  FymAPIAdminStatsDetailsResponse,
  FymAPIAdminStatsListResponse,
  FymAPIAdminStatsRemoveStatDataResponse
} from './types/FymAPIAdminStatsListResponse';
// eslint-disable-next-line import/no-cycle
export type RequestHeaders = { [key: string]: string };

export class FymAPI {
  private readonly baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  // eslint-disable-next-line class-methods-use-this
  private requestHeaders(accessToken: string | null = null, deviceId?: string): RequestHeaders {
    const headers: RequestHeaders = {
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/json'
    };
    if (accessToken !== null) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
    if (deviceId) {
      headers['x-device-id'] = deviceId;
    }
    return headers;
  }

  async login(
    params: RequestParams<{
      email: string;
      password: string;
    }>
  ): Promise<FymAPILoginResponse> {
    const { email, password } = params;
    const data: any = { email, password };
    const response = await fetch(`${this.baseUrl}authorization/login`, {
      method: 'POST',
      headers: this.requestHeaders(),
      body: JSON.stringify(data)
    });

    if (response.status === 403) {
      throw new Error(`Unauthorized`);
    }
    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(`[authorization/login] got wrong response[${response.status}]: ${text}`);
    }

    const jsonBody = await response.json();
    return { token: jsonBody.token };
  }

  async adminLicensesList({
    token,
    page,
    search
  }: RequestParams<{
    token: string;
    page: number;
    search: string;
  }>): Promise<FymAPIAdminLicensesListResponse> {
    const response = await fetch(`${this.baseUrl}admin/licenses?${qs.stringify({ page, search })}`, {
      method: 'GET',
      headers: this.requestHeaders(token)
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(`[admin/licenses] got wrong response[${response.status}]: ${text}`);
    }

    const jsonBody = await response.json();
    return jsonBody as FymAPIAdminLicensesListResponse;
  }

  async adminLicensesDetails({
    token,
    licenseId
  }: RequestParams<{
    token: string;
    licenseId: number;
  }>): Promise<FymAPIAdminLicensesDetailsResponse> {
    const response = await fetch(`${this.baseUrl}admin/licenses/${licenseId}`, {
      method: 'GET',
      headers: this.requestHeaders(token)
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(`[admin/licenses/${licenseId}] got wrong response[${response.status}]: ${text}`);
    }

    const jsonBody = await response.json();
    return jsonBody as FymAPIAdminLicensesDetailsResponse;
  }

  async adminLicensesSendLicenseEmail({
    token,
    licenseId,
    email
  }: RequestParams<{
    token: string;
    licenseId: number;
    email: string;
  }>): Promise<FymAPIAdminLicensesSendLicenseEmailResponse> {
    const response = await fetch(`${this.baseUrl}admin/licenses/${licenseId}/sendLicenseEmail`, {
      method: 'POST',
      headers: this.requestHeaders(token),
      body: JSON.stringify({ email })
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(`[admin/licenses/${licenseId}/sendLicenseEmail] got wrong response[${response.status}]: ${text}`);
    }

    const jsonBody = await response.json();
    return jsonBody as FymAPIAdminLicensesSendLicenseEmailResponse;
  }

  async adminLicensesChangeEmail({
    token,
    licenseId,
    email
  }: RequestParams<{
    token: string;
    licenseId: number;
    email: string;
  }>): Promise<FymAPIAdminLicensesSendLicenseEmailResponse> {
    const response = await fetch(`${this.baseUrl}admin/licenses/${licenseId}/changeEmail`, {
      method: 'POST',
      headers: this.requestHeaders(token),
      body: JSON.stringify({ email })
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(`[admin/licenses/${licenseId}/changeEmail] got wrong response[${response.status}]: ${text}`);
    }

    const jsonBody = await response.json();
    return jsonBody as FymAPIAdminLicensesChangeEmailResponse;
  }

  async adminLicensesRemovePersonalData({
    token,
    licenseId
  }: RequestParams<{
    token: string;
    licenseId: number;
  }>): Promise<FymAPIAdminLicensesRemovePersonalDataResponse> {
    const response = await fetch(`${this.baseUrl}admin/licenses/${licenseId}/removePersonalData`, {
      method: 'POST',
      headers: this.requestHeaders(token)
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(
        `[admin/licenses/${licenseId}/removePersonalData] got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return jsonBody as FymAPIAdminLicensesRemovePersonalDataResponse;
  }

  async adminLicensesLogoutDevices({
    token,
    licenseId
  }: RequestParams<{
    token: string;
    licenseId: number;
  }>): Promise<FymAPIAdminLicensesRemovePersonalDataResponse> {
    const response = await fetch(`${this.baseUrl}admin/licenses/${licenseId}/logoutAllDevices`, {
      method: 'POST',
      headers: this.requestHeaders(token)
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(`[admin/licenses/${licenseId}/logoutAllDevices] got wrong response[${response.status}]: ${text}`);
    }

    const jsonBody = await response.json();
    return jsonBody as Record<string, never>;
  }

  async adminLicensesSwitchRealmPartition({
    token,
    licenseId
  }: RequestParams<{
    token: string;
    licenseId: number;
  }>): Promise<void> {
    const response = await fetch(`${this.baseUrl}admin/licenses/${licenseId}/switchRealmPartition`, {
      method: 'PUT',
      headers: this.requestHeaders(token)
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(
        `[admin/licenses/${licenseId}/switchRealmPartition] got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async adminDevicesSwitchRealmPartition({
    token,
    deviceId
  }: RequestParams<{
    token: string;
    deviceId: string;
  }>): Promise<void> {
    const response = await fetch(`${this.baseUrl}admin/devices/switchRealmPartition`, {
      method: 'PUT',
      headers: this.requestHeaders(token, deviceId)
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(
        `[admin/devices/switchRealmPartition] for device ${deviceId} got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async adminStatsList({
    token,
    page,
    search
  }: RequestParams<{
    token: string;
    page: number;
    search: string;
  }>): Promise<FymAPIAdminStatsListResponse> {
    const response = await fetch(`${this.baseUrl}admin/stats?${qs.stringify({ page, search })}`, {
      method: 'GET',
      headers: this.requestHeaders(token)
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(`[admin/stats] got wrong response[${response.status}]: ${text}`);
    }

    const jsonBody = await response.json();
    return jsonBody as FymAPIAdminStatsListResponse;
  }

  async adminStatsDetails({
    token,
    statId
  }: RequestParams<{
    token: string;
    statId: number;
  }>): Promise<FymAPIAdminStatsDetailsResponse> {
    const response = await fetch(`${this.baseUrl}admin/stats/${statId}`, {
      method: 'GET',
      headers: this.requestHeaders(token)
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(`[admin/stats/${statId}] got wrong response[${response.status}]: ${text}`);
    }

    const jsonBody = await response.json();
    return jsonBody as FymAPIAdminStatsDetailsResponse;
  }

  async adminStatsRemoveStatData({
    token,
    statId
  }: RequestParams<{
    token: string;
    statId: number;
  }>): Promise<FymAPIAdminStatsRemoveStatDataResponse> {
    const response = await fetch(`${this.baseUrl}admin/stats/${statId}/removeStatData`, {
      method: 'POST',
      headers: this.requestHeaders(token)
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(`[admin/stats/${statId}/removeStatData] got wrong response[${response.status}]: ${text}`);
    }

    const jsonBody = await response.json();
    return jsonBody as FymAPIAdminStatsRemoveStatDataResponse;
  }

  static fullURL(path: string | undefined) {
    if (!path) {
      return undefined;
    }
    return `${config.fymServerAPI}${path}`;
  }
}

export const fymApi = new FymAPI(config.fymAPI);
