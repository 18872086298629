import React, { useEffect, useMemo } from 'react';
import { NextComponentType } from 'next';
import Head from 'next/head';
import { defineMessages, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import { identifyAnonymous, trackPageView, useStoreMarketingValues } from '../components/googleAnalytics';
import { nextAsset } from '../components/nextAsset';
import { useIsClient } from '../components/hooks/useIsClient';
import { availableLanguages, blogAvailableLanguages } from '../appTypes';

fontAwesomeConfig.autoAddCss = false;

declare global {
  interface Window {
    dataLayer: any | undefined;
    fbq: any | undefined;
    gtag: any | undefined;
    gTagID: string;
    analytics: any | undefined;
    Paddle: any;
    plausible: any | undefined;
    clarity: any | undefined;
    onBlogCTAClicked: (e: React.MouseEvent<HTMLElement>) => void;
  }
}

const { pageDescription, pageName } = defineMessages({
  pageDescription: {
    id: 'sml.web.page.description-smartlinks',
    defaultMessage: 'Welcome fans from around the world to your music, with links that do more.'
  },
  pageName: {
    id: 'sml.web.page-smartlinks.name',
    defaultMessage: 'SmartLinksApp.com'
  }
});

const BaseComponent: React.FC<{ Component: NextComponentType; pageProps: any }> = ({ Component, pageProps }) => {
  const intl = useIntl();
  const router = useRouter();
  const { locale } = router;

  useStoreMarketingValues();
  const isClient = useIsClient();
  useEffect(() => {
    if (!isClient) {
      return;
    }
    trackPageView();
  }, [router.asPath, isClient]);

  useEffect(() => {
    if (!isClient) {
      return;
    }
    identifyAnonymous();
  }, [isClient]);

  const title = intl.formatMessage(pageName);
  const description = intl.formatMessage(pageDescription);
  const promoImg = nextAsset(`logos-sml/promo-${locale}@2x.png`);
  const isDynamicContent = useMemo(() => router.asPath.startsWith(`/blog`), [router.asPath]);

  const canonicalURL = useMemo(() => {
    const hasDynamicContent = blogAvailableLanguages.find((l: string) => l === locale);
    if (hasDynamicContent || !isDynamicContent) {
      return null;
    }
    return router.asPath.replace(`/${locale}`, ``);
  }, [isDynamicContent, locale, router.asPath]);

  const availableLangs = isDynamicContent ? blogAvailableLanguages : availableLanguages;

  /* eslint-disable react/no-danger */
  return (
    <>
      <Head>
        <meta name="apple-mobile-web-app-title" content="Music24" />
        <meta name="mobile-web-app-capable" content="yes" />

        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} key="itemProp-description" />
        <meta name="description" content={description} key="description" />
        <meta itemProp="image" content={promoImg} key="image" />
        {availableLangs &&
          availableLangs.map((l: string) => {
            let currentURL = router.asPath;
            if (router.defaultLocale !== l) {
              currentURL = `/${l}${router.asPath}`;
            }
            return <link rel="alternate" key={l} hrefLang={l} href={currentURL} />;
          })}
        {canonicalURL && <link rel="canonical" href={canonicalURL} />}

        <meta property="twitter:site" content="@smartlinksapp" key="twitter-site" />
        <meta property="twitter:title" content={title} key="twitter-title" />
        <meta property="twitter:description" content={description} key="twitter-description" />
        <meta property="twitter:image" content={promoImg} key="twitter-image" />
        <meta property="twitter:image:width" content="2400" key="twitter-image-width" />
        <meta property="twitter:image:height" content="1200" key="twitter-image-height" />
        <meta property="twitter:card" content="summary_large_image" key="twitter-card" />

        <meta property="og:image" content={promoImg} key="fb-image" />
        <meta property="og:type" content="website" key="fb-type" />
        <meta property="og:url" content={router.asPath} key="fb-url" />
        <meta property="fb:app_id" content="1607923582824195" key="fb-app-id" />
        <meta property="og:title" content={title} key="fb-title" />
        <meta property="og:description" content={description} key="fb-description" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Organization',
              legalName: 'FreeYourMusic Limited',
              url: 'https://music24.com/',
              sameAs: ['https://twitter.com/smartlinksapp', 'https://www.facebook.com/freeyourmusicapp/'],
              email: 'contact@music24.com',
              brand: {
                '@type': 'Brand',
                logo: `https://music24.com${nextAsset('logos-sml/logo-full-light@1024px.png', true)}`,
                name: 'Music24',
                description
              }
            })
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'SoftwareApplication',
              operatingSystem: 'iOS,Android,Windows,Linux,macOS',
              name: 'Music24',
              image: `https://music24.com${nextAsset('logos-sml/logo-square-rounded@1024px.png', true)}`,
              url: 'https://music24.com/',
              applicationCategory: 'https://schema.org/UtilitiesApplication',
              downloadUrl: 'https://music24.com/download',
              offers: {
                '@type': 'Offer',
                price: '0'
              }
            })
          }}
        />
      </Head>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} />
      <svg style={{ display: 'block', width: 0, height: 0, lineHeight: 0 }}>
        <radialGradient id="fym-radial" cx="50%" cy="50%" r="97.597118%">
          <stop offset="0" stopColor="#de376a" />
          <stop offset="1" stopColor="#6417fb" />
        </radialGradient>
      </svg>
    </>
  );
  /* eslint-enable react/no-danger */
};

export const Base = React.memo(BaseComponent);
